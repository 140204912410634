<template>
  <page-list  class="page-list__spec">
    <template #header>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline=true>
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            :disabled="mode === 'edit'"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addHandle()">添 加</el-button>
        </el-form-item>
      </el-form>
    </template>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        v-loading="loading"
        element-loading-background="transparent"
        @row-dblclick="editHandle"
        row-key="id"
      >
        <el-table-column
          prop="id"
          label="ID"
          min-width="40"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <!-- <el-table-column
          prop="type"
          label="类型"
          min-width="60"
          header-align="center"
          align="center"
          :formatter="typeFormatter"
        >
        </el-table-column> -->
        <el-table-column
          prop="shopId"
          label="门店"
          min-width="120"
          header-align="center"
          align="center"
          show-overflow-tooltip
          :formatter="shopFormatter"
        >
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="120">
          <template v-slot="scope">
            <span v-html="scope.row.name"></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="flag"
          label="标志"
          min-width="80"
          header-align="center"
          align="center"
          :formatter="tagFormatter"
        >
        </el-table-column>
        <el-table-column
          prop="image"
          label="图片"
          min-width="40"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <el-image
              v-if="scope.row.image"
              style="width: 30px; height: 30px"
              :src="scope.row.image.split(',')[0]"
              :preview-src-list="scope.row.image.split(',')"
              preview-teleported
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="weigh"
          label="权重"
          min-width="60"
          header-align="center"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          min-width="40"
          header-align="center"
          align="center"
        >
          <template v-slot="scope">
            <span
              class="fa fa-circle"
              :style="{ color: USER_STATUS[scope.row.status].color }"
              >{{ " " + USER_STATUS[scope.row.status].label }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="center" align="center" min-width="90">
          <template v-slot="scope">
            <el-button
                class="icon-btn copy-btn fa fa-copy"
                @click="copyHandle(scope.row)"
                >复制</el-button
              >
            <el-button
              class="icon-btn move-handle fa fa-arrows"
              @click="editHandle(scope.row)"
            >
              <!-- 编辑 -->
            </el-button>
            <el-button
              class="icon-btn fa fa-pencil"
              type="primary"
              @click="editHandle(scope.row)"
            >
              <!-- 编辑 -->
            </el-button>
            <el-button
              class="icon-btn fa fa-trash"
              type="danger"
              @click="delHandle(scope.row)"
            >
              <!-- 删除 -->
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-model="paginationData"
        :total="total"
        @change="getList"
      ></pagination>
  </page-list>
</template>

<script>
import { CATEGORY_OPTIONS,CATEGORY_TAG_OPTIONS } from "@/helpers/constants";
import { cloneDeep } from "lodash";
import { USER_STATUS } from "@/helpers/constants";
import { msgErr, msgSuc } from "@/helpers/message";
import {
  listByPage,
  deleteById,
  drag,
} from "@/apis/unidrink/category";
import Sortable from "sortablejs";
const calls = [];
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    shopOptions:{
      type: Array,
      default: ()=>[],
    }
  },
  data() {
    return {
      USER_STATUS: USER_STATUS,
      tableData: [],
      loading: false,
      initForm: {
        shopId:[]
      },
      form: {},
      paginationData: {},
    };
  },
  emits: ["add", "edit"],
  created() {
    console.log(calls);
  },
  mounted() {},
  methods: {
    copyHandle(row) {
      const copyData = cloneDeep(row);
      delete copyData.id;
      this.$emit("copy", copyData);
    },
    editHandle(row) {
      this.$emit("edit", row);
    },
    getList() {
      this.loading = true;
      this.form.type = this.type=="all"?"":this.type;
      listByPage({...this.form,...this.paginationData})
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;

          const tbody = document.querySelector(".el-table__body-wrapper tbody");
          Sortable.create(tbody, {
            handle: ".move-handle",
            onEnd: ({ newIndex, oldIndex }) => {
              if (newIndex === oldIndex) {
                return;
              }
              console.log("Sortable onEnd", newIndex, oldIndex);

              let newItem = this.tableData[newIndex];
              let oldItem = this.tableData[oldIndex];
              let tempSn = newItem.sn;
              newItem.sn = oldItem.sn;
              oldItem.sn = tempSn;

              
              const dragItem = this.tableData.splice(oldIndex, 1)[0];
              this.tableData.splice(newIndex, 0, dragItem);
              //拿到新旧两个对象，互换顺序;
              drag(
                this.tableData.map((item) => item.id).join(",")
              )
                .then(({ code, result, message }) => {
                  if (code === "0000") {
                    msgSuc("操作成功！");
                  } else {
                    msgErr(message);
                  }
                })
                .finally(() => {
                  this.getList();
                });
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    addHandle() {
      this.$emit("add");
    },

    delHandle(row) {
      this.$confirm("确定删除此项？", "温馨提示").then(() => {
        deleteById(row.id).then(({ code, result, message }) => {
          if (code === "0000") {
            msgSuc("操作成功！");
            this.getList();
          } else {
            msgErr(message);
          }
        });
      });
    },

    shopFormatter(row,column,cellValue,index){
      for(let i=0;i<this.shopOptions.length;i++){
        let shopInfo = this.shopOptions[i];
        if(shopInfo.id == cellValue){
          return shopInfo.name;
        }
      }
      return cellValue;
    },

    typeFormatter(row,column,cellValue,index){
      for(let i=0;i<CATEGORY_OPTIONS.length;i++){
        let categoryInfo = CATEGORY_OPTIONS[i];
        if(categoryInfo.value == cellValue){
          return categoryInfo.label;
        }
      }
      return cellValue;
    },
// { label: "折扣", value: "discount" },
//   { label: "新品", value: "new" },
    tagFormatter(row,column,cellValue,index){
      if(!cellValue){
        return "";
      } 
      let tags = cellValue.split(",");
      let arr = [];
      for(let i=0;i<tags.length;i++){
        arr.push(this.getTagLabel(tags[i]));
      }
      return arr.join(",");
    },

    getTagLabel(value){
      for(let i=0;i<CATEGORY_TAG_OPTIONS.length;i++){
        if(value == CATEGORY_TAG_OPTIONS[i].value){
          return CATEGORY_TAG_OPTIONS[i].label;
        }
      }
      return value;
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-page_container {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .panel-page_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
  }
}
.move-handle {
  background-color: #2c3e50;
  color: #ffffff;
}
.copy-btn {
  background-color: #3498db;
  color: #ffffff;
  width: 60px;
}
</style>
